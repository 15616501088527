import { Button, Paper } from '@mui/material';
import { useState } from 'react';
import { useEffect } from 'react';
import PostListCard from '../PostListCard/PostListCard';
import './PostsList.scss';

export default function PostsList() {
  const [posts, setPosts] = useState([]);

  //example below on how to correctly preserve previous state across state updates
  /* setPosts((previousState) => {
    return { ...previousState, newPost: event?.target.value}
  }) */

  const postsList = posts.map(
    ({ problem, solution, category, date }, index) => (
      <div key={index}>
        <PostListCard
          problem={problem}
          solution={solution}
          category={category}
          date={date}
        />
      </div>
    ),
  );

  const getPosts = async () => {
    const posts = fetch('http://localhost:3000/api/post')
      .then((response) => response.json())
      .then((data) => {
        return data;
      });

    return await posts;
  };

  const setPostsToState = async () => {
    setPosts(await getPosts());
  };

  useEffect(() => {
    if (posts === undefined || posts.length === 0) {
      console.log('Setting posts to state, standby...');
      setPostsToState();
    } else {
      console.log('Posts state has been set...', posts);
    }
  });

  return (
    <div className="PostsList">
      <Paper elevation={3}>
        <div className="updatePostButton">
          <Button variant="contained" onClick={setPostsToState}>
            {' '}
            Update Posts
          </Button>
        </div>
        <br></br>
        {postsList}
        <div className="updatePostButton">
          <Button variant="contained" onClick={setPostsToState}>
            {' '}
            Update Posts
          </Button>
        </div>
      </Paper>
    </div>
  );
}
