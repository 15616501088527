import "./PostListCard.scss";

interface PostData {
  key?: string;
  problem?: string;
  solution?: string;
  category?: string;
  date?: string;
}

export default function PostListCard(props: PostData) {
  const problem = props.problem;
  const solution = props.solution;
  const category = props.category;

  return (
    <div className="PostListCard">
      <ul>
        <li className="problem">
          <h4>problem:</h4> {problem}
        </li>
        <li className="solution">
          <h4>solution:</h4> {solution}
        </li>
        <li className="category">
          <h4>category:</h4> {category}
        </li>
      </ul>
    </div>
  );
}
