import Header from "../components/header/Header/Header";
import AdSense from "../components/other/AdSense/AdSense";

export default function ContactView() {
  return (
    <div>
      <Header></Header>
      <h2>Contact View...</h2>
      {/* <AdSense dataAdSlot='X7XXXXXX5X'></AdSense> */}
    </div>
  );
}
