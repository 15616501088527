import React from "react";
import ReactDOM from "react-dom/client";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import "./index.css";
import AboutView from "./views/AboutView";
import ContactView from "./views/ContactView";
import ErrorView from "./views/ErrorView";
import PostsView from "./views/PostsView";
import ProfileView from "./views/ProfileView";

const router = createBrowserRouter([
  {
    path: "/",
    element: <PostsView />,
    errorElement: <ErrorView />,
  },
  {
    path: "/profile",
    element: <ProfileView />,
    errorElement: <ErrorView />,
  },
  {
    path: "/about",
    element: <AboutView />,
    errorElement: <ErrorView />,
  },
  {
    path: "/contact",
    element: <ContactView />,
    errorElement: <ErrorView />,
  },
]);

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);
