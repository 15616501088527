import "./Footer.scss";

export default function Footer() {
  return (
    <div className="Footer">
      <h4>
        Insert Footer data here...
      </h4>
    </div>
  )
}
