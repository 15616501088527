import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  TextField,
} from '@mui/material';
import { useReducer, useState } from 'react';
import './PostInputForm.scss';

export default function PostInputForm() {
  const [formData, setFormData] = useState({});

  const handleInput = (event: any) => {
    const name = event.target.name;
    const value = event.target.value;

    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (event: any) => {
    event.preventDefault();

    fetch('http://localhost:3000/api/post', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(formData),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log('Success:', data);
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  };

  return (
    <div className="PostInputForm">
      <Paper elevation={3}>
        <form className="input-form" onSubmit={handleSubmit}>
          <label>
            <TextField
              onChange={handleInput}
              id="outlined-basic"
              label="Problem"
              name="problem"
              variant="outlined"
              multiline
              rows={2}
              sx={{ width: '46em', marginBottom: 2 }}
            />
            <br />
            <TextField
              onChange={handleInput}
              id="outlined-basic"
              label="Solution"
              name="solution"
              variant="outlined"
              multiline
              rows={2}
              sx={{ width: '46em', marginBottom: 2 }}
            />
            <div className="formControl">
              <FormControl variant="outlined" fullWidth>
                <InputLabel id="demo-simple-select-label">Category</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  //value={category}
                  label="Category"
                  name="category"
                  onChange={handleInput}
                >
                  <MenuItem value="Arts & entertainment">
                    Arts & Entertainment
                  </MenuItem>
                  <MenuItem value="Autos & Vehicles">Autos & Vehicles</MenuItem>
                  <MenuItem value="Beauty & Fitness">Beauty & Fitness</MenuItem>
                  <MenuItem value="Books & Literature">
                    Books & Literature
                  </MenuItem>
                  <MenuItem value="Business & Industrial">
                    Business & Industrial
                  </MenuItem>
                  <MenuItem value="Computers & Electronics">
                    Computers & Electronics
                  </MenuItem>
                  <MenuItem value="Finance">Finance</MenuItem>
                  <MenuItem value="Food & Drink">Food & Drink</MenuItem>
                  <MenuItem value="Games">Games</MenuItem>
                  <MenuItem value="Health">Health</MenuItem>
                  <MenuItem value="Hobbies & Leisure">
                    Hobbies & Leisure
                  </MenuItem>
                  <MenuItem value="Home & Garden">Home & Garden</MenuItem>
                  <MenuItem value="Internet & Telecom">
                    Internet & Telecom
                  </MenuItem>
                  <MenuItem value="Jobs & Education">Jobs & Education</MenuItem>
                  <MenuItem value="Law & Government">Law & Government</MenuItem>
                  <MenuItem value="News">News</MenuItem>
                  <MenuItem value="Online Communities">
                    Online Communities
                  </MenuItem>
                  <MenuItem value="People & Society">People & Society</MenuItem>
                  <MenuItem value="Pets & Animals">Pets & Animals</MenuItem>
                  <MenuItem value="Real Estate">Real Estate</MenuItem>
                  <MenuItem value="Reference">Reference</MenuItem>
                  <MenuItem value="Science">Science</MenuItem>
                  <MenuItem value="Shopping">Shopping</MenuItem>
                  <MenuItem value="Sports">Sports</MenuItem>
                  <MenuItem value="Travel">Travel</MenuItem>
                  <MenuItem value="Other">Other</MenuItem>
                </Select>
              </FormControl>
            </div>
            <div className="submitButton">
              <Button type="submit" variant="contained">
                Submit
              </Button>
            </div>
          </label>
        </form>
      </Paper>
    </div>
  );
}
