import FilterPostsButton from "../components/body/FilterPostsButton/FilterPostsButton";
import CreatePostButton from "../components/body/CreatePostButton/CreatePostButton";
import PostInputForm from "../components/body/PostInputForm/PostInputForm";
import PostsList from "../components/body/PostsList/PostsList";
import Header from "../components/header/Header/Header";
import Footer from "../components/footer/Footer/Footer";
import AdSense from "../components/other/AdSense/AdSense";

export default function PostsView() {
  return (
    <div>
      <Header></Header>
      {/* <AdSense dataAdSlot='X7XXXXXX5X'></AdSense> */}
      <PostInputForm></PostInputForm>
      <PostsList></PostsList>
      {/* <AdSense dataAdSlot='X7XXXXXX5X'></AdSense> */}
      <Footer></Footer>
    </div>
  );
}
